// src/ComingSoon.js
import React from 'react';
import './ComingSoon.css';
import logo from './logo.png';

const ComingSoon = () => {
  return (
    <div className="coming-soon">
      <img src={logo} alt="Logo" className="coming-soon-logo" />
      <h1>Site em construção!</h1>
    </div>
  );
};

export default ComingSoon;
